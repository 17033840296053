import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"

const Video = ({ title, video, anchor }) => (
  <>
    {anchor && <AnchorLink to={anchor} />}

    <VideoContainerStyled className="video">
      <Container>
        <Row>
          {title && (
            <Col className={"no-padding"} xs="12">
              <h2 className="video-title">{title}</h2>
            </Col>
          )}
          <Col xs="12" className="video-container">
            <div className="video-iframe">
              <iframe
                width="560"
                height="315"
                src={video}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
      </Container>
    </VideoContainerStyled>
  </>
)

export default Video

export const VideoContainerStyled = styled.div`
  padding-top: 30px;

  .video-title {
    padding: 30px 0;
    font-size: 20px;
  }
  .video-iframe {
    padding-top: calc(315 / 560 * 100%);
    position: relative;
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`
